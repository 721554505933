<script setup lang="ts">
import type { ProductSearchResultFragment } from '#graphql-operations'

const props = defineProps<{
  item?: ProductSearchResultFragment
  freeShipping?: boolean
}>()

defineEmits<{
  (event: 'click'): void
}>()
const eligibleToDisplayPriceBeforeDiscount = computed(() => isEligibleToDisplayPriceBeforeDiscount(props.item))

const rating = computed(() => getRating(props.item?.id, 1, [4]))
const localePath = useLocalePath()

const discountPercentage = computed(() => {
  if (!props.item)
    return 0

  if (props.item.price.min !== props.item.price.max)
    return 0

  const originalPrice = props.item.customProductMappings?.priceBeforeDiscount
  const currentPrice = props.item.price.min

  if (originalPrice && currentPrice) {
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100
    return Math.round(discount)
  }

  return 0
})

function isEligibleToDisplayPriceBeforeDiscount(item?: ProductSearchResultFragment): boolean {
  if (!item)
    return false

  if (!item.customProductMappings?.priceBeforeDiscount)
    return false

  if (item.customProductMappings?.priceBeforeDiscount <= item.price.max)
    return false

  if (item.price.min !== item.price.max)
    return false

  return true
}
</script>

<template>
  <NCard class="group relative overflow-hidden">
    <!-- Image wrapper -->
    <div class="relative h-auto w-full">
      <slot name="image">
        <NSkeleton
          v-if="!item || !item.featuredAsset?.preview"
          class="aspect-[9/10] h-full w-full flex-grow object-cover" wave shape="rect"
        />
        <NImg
          v-else
          class="aspect-[9/10] flex-grow object-cover"
          :src="item.featuredAsset.preview" width="300" height="300" quality="40" preset="whiteContrast"
          :alt="item.name"
        />
      </slot>

      <slot name="color">
        <!--        -->
      </slot>
      <slot name="badge">
        <ContentGuard>
          <div
            v-if="discountPercentage > 0"
            class="absolute left-2 top-2 rounded-md bg-primary-2 px-1.5 py-0.5 text-[0.8rem] text-white font-bold opacity-95 sm:text-xs"
          >
            <span class="mt-0.5">- {{ discountPercentage }} %</span>
          </div>
        </ContentGuard>
      </slot>
    <!--  <div class="absolute bottom-0 w-full flex justify-between gap-x-2 bg-green-800/80 px-2 py-1">
        <div>
          <NSkeleton v-if="!item || !item.price" class="w-full" />
          <span v-else class="text-[11px] text-white font-bold">{{ $t('product.in_stock') }}</span>
        </div>
        <div>
          <NSkeleton v-if="!item || !item.price" class="w-full" />
          <span v-else class="text-[11px] text-white font-bold">{{ $t('general.24_shipping.label') }}</span>
        </div>
      </div> -->
    </div>

    <div class="flex flex-1 flex-col px1 py1 sm:px4 sm:py4 xs:px2 xs:py2">
      <slot name="price">
        <NSkeleton v-if="!item || !item.price" />
        <Price
          v-else-if="eligibleToDisplayPriceBeforeDiscount"
          :regular="item.customProductMappings.priceBeforeDiscount || 0" :currency="item.currency"
          :special="item.price"
        />
        <Price v-else :regular="item.price" :currency="item.currency" />
      </slot>

      <h3 class="line-clamp-2 overflow-hidden text-xs text-context sm:h10 sm:text-sm xs:text-sm" n=" slate5 dark:slate4">
        <NSkeleton v-if="!item || !item.id || !item.slug || !item.name" class="w-full" wave />
        <NuxtLink
          v-else
          :to="localePath(`/products/${item.id}/${item.slug}`)"
          @click="$emit('click')"
        >
          <span aria-hidden="true" class="absolute inset-0" />
          {{ item.name }}
        </NuxtLink>
      </h3>
      <div class="flex flex-col items-start justify-between pt1 xs:flex-row xs:items-center">
        <!-- <template v-if="freeShipping¨ || freeShipping != null">
          <NSkeleton v-if="!item || !item.price" class="w-3/4" />
          <span v-else>
            <ContentGuard>
              <span class="text-[0.6rem] text-primary-3 sm:text-xs">
                {{ $t('product.free_shipping.label').toUpperCase() }}
              </span>
            </ContentGuard>
          </span>
        </template> -->
        <slot name="rating">
        <!--  <ContentGuard>
            <Rating :rating="rating" class="-ml0.5" />
          </ContentGuard> -->
        </slot>
      </div>
    </div>
  </NCard>
</template>
